import React, { useState } from "react";
import Fade1 from "./assests/process_section/1.png";
import Fade2 from "./assests/process_section/2.png";
import Fade3 from "./assests/process_section/3.png";

import Checkbox1 from "./assests/process_section/checkboxFilled.png";
import Checkbox from "./assests/process_section/checkbox.png";

import Step1 from "./assests/process_section/Step1.png";
import Step2 from "./assests/process_section/Step2.png";
import Step3 from "./assests/process_section/Step3.png";

import Model1 from "./assests/process_section/Modern_plan/Modal1.png";
import Model2 from "./assests/process_section/Modern_plan/Modal2.png";
import Model3 from "./assests/process_section/Modern_plan/Model3.png";
import CheckBoxBlue from "./assests/process_section/Modern_plan/CheckBoxBlue.png";
import { Link } from 'react-scroll';

// const ProcessSection = () => {
//   return (
//     <div className="mb-24">
//       <div className="font-montserrat text-[28px] md:text-[3rem] lg:text-[3rem] font-bold text-[#1A1A1A] text-center mt-24">
//         How to Invest ?
//       </div>
//       <div className="group relative flex flex-row pt-8 items-center gap-4 justify-center overflow-x-auto overflow-y-hidden border-gray-200 dark:border-gray-700 border-b-[1px] sm:border-b-[2px] sm:border-gray-400 dark:sm:border-gray-200 lg:gap-10 max-w-screen-md mx-auto">
//       <div className="absolute inset-0 border-black opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none z-20"></div>
//       <button
//         className={`relative font-poppins text-[15px] lg:text-lg inline-flex items-center pb-2 font-bold h-30 px-0 sm:px-4 -mb-px text-center border-b-[3px] sm:text-base ${
//          "text-black border-black z-10"
//         }`}
//       >
//         Stock Recommendations Plan
//       </button>

//       <button
//         className={`relative inline-flex text-[15px] lg:text-lg items-center pb-2 font-bold h-30 px-4 -mb-px text-center border-b-[3px] sm:text-base ${
//           "text-[#808080] border-transparent z-10"
//         }`}
//       >
//         Model Portfolio Plan
//       </button>
//     </div>
//       {/* Stock Recommendations Plan Section */}
//       <div className="mt-12">
//         <div className="flex flex-col justify-center mx-2 mt-9 md:mt-12">
//           <div className="relative mx-auto mt-12 md:mt-12">
//             {/* Step 1 */}
//             <div className="md:w-[70rem] mb-20 flex flex-col md:flex-row justify-between">
//               <div className="flex justify-center items-center absolute w-8 left-0">
//                 <div className="flex flex-col items-center justify-center mt-20">
//                   <img src={Checkbox1} alt="Checkbox" className="mb-5" />
//                   <div className="border-l-4 border-[#E8B634]/50 h-[50vh] sm:h-64 md:h-52 lg:h-80 xl:h-[12rem]"></div>
//                 </div>
//               </div>
//               <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
//                 <div className="w-full md:w-[42%] flex flex-col">
//                   <div className="text-2xl md:text-4xl font-bold">
//                     <img src={Fade1} className="w-20" alt="Choose Your Plan" />
//                     Choose Your Plan
//                   </div>
//                   <div className="text-base md:text-xl mt-4">
//                     Choose the stock tips plan that fits your investment goals.
//                   </div>
//                 </div>
//                 <div className="w-full md:w-[280px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-0 pr-4 md:pr-0">
//                   <img src={Step1} alt="Step 1" className="w-full max-w-[250px] md:max-w-full ml-14" />
//                 </div>
//               </div>
//             </div>

//             {/* Step 2 */}
//             <div className="md:w-[70rem] mb-20 flex flex-col md:flex-row justify-between">
//               <div className="flex justify-center items-center absolute w-8 left-0">
//                 <div className="flex flex-col items-center justify-center mt-24">
//                   <img src={Checkbox} alt="Checkbox" className="mb-2" />
//                   <div className="border-l-4 mt-4 border-[#E8E8E8]/75 h-[60vh] sm:h-64 md:h-64 lg:h-80 xl:h-[12rem]"></div>
//                 </div>
//               </div>
//               <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
//                 <div className="w-full md:w-[60%] flex flex-col">
//                   <div className="text-2xl md:text-4xl font-bold">
//                     <img src={Fade2} className="w-20" alt="Receive Tips & Invest" />
//                     Receive Recommendations & Invest
//                   </div>
//                   <div className="text-base md:text-xl mt-4">
//                     Get stock recommendations on your dashboard, connect your broker, and place orders with a few clicks.
//                   </div>
//                 </div>
//                 <div className="w-full md:w-[250px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-0 pr-4 md:pr-0">
//                   <img src={Step2} alt="Step 2" className="w-[90%] ml-10" />
//                 </div>
//               </div>
//             </div>

//             {/* Step 3 */}
//             <div className="md:w-[70rem] flex flex-col md:flex-row justify-between">
//               <div className="flex justify-center items-center absolute w-8 left-0">
//                 <div className="flex flex-col items-center justify-center mt-20">
//                   <img src={Checkbox} alt="Checkbox" />
//                 </div>
//               </div>
//               <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
//                 <div className="w-full md:w-[60%] flex flex-col text-black">
//                   <div className="text-2xl md:text-4xl font-bold">
//                     <img src={Fade3} className="w-24" alt="Track your Portfolio" />
//                     Track Your Portfolio
//                   </div>
//                   <div className="text-base md:text-xl mt-4">
//                     Once you've invested, monitor your portfolio to see how your stocks are performing. Keep an eye on your investments as they grow.
//                   </div>
//                 </div>
//                 <div className="w-full md:w-[250px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-14">
//                   <img src={Step3} alt="Step 3" className="w-[75%] ml-11" />
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="flex items-center justify-center">
//             <Link
//               to="subscriptionSection" 
//               smooth={true} 
//               duration={800} 
//               className="inline-block w-full sm:w-auto mt-20 rounded-md bg-[#0D084D] text-white font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
//             >
//               <button className="w-full px-10 py-3 text-base sm:text-sm lg:text-base">
//                 View All Stock Tips Plans
//               </button>
//             </Link>
//           </div>
//         </div>
//       </div>



//       {/* Model Portfolio Plan Section */}
//       <div className="mt-24">


//       <div className="font-montserrat text-[28px] md:text-[3rem] lg:text-[3rem] font-bold text-[#1A1A1A] text-center mt-24">
//         How to Invest ?
//       </div>
//       <div className="group relative flex flex-row pt-8 items-center gap-4 justify-center overflow-x-auto overflow-y-hidden border-gray-200 dark:border-gray-700 border-b-[1px] sm:border-b-[2px] sm:border-gray-400 dark:sm:border-gray-200 lg:gap-10 max-w-screen-md mx-auto">
//       <div className="absolute inset-0 border-black opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none z-20"></div>
//       <button
//         className={`relative font-poppins text-[15px] lg:text-lg inline-flex items-center pb-2 font-bold h-30 px-0 sm:px-4 -mb-px text-center border-b-[3px] sm:text-base ${
//           "text-[#808080] border-transparent  z-10"
//         }`}
//       >
//         Stock Recommendations Plan
//       </button>

//       <button
//         className={`relative inline-flex text-[15px] lg:text-lg items-center pb-2 font-bold h-30 px-4 -mb-px text-center border-b-[3px] sm:text-base ${
//           "text-black border-black z-10"

//         }`}
//       >
//         Model Portfolio Plan
//       </button>
//     </div>


//         <div className="flex flex-col justify-center mx-2 mt-9 md:mt-12">
//           <div className="relative mx-auto mt-12 md:mt-12">
//             {/* Step 1 */}
//             <div className="md:w-[70rem] mb-20 flex flex-col md:flex-row justify-between">
//               <div className="flex justify-center items-center absolute w-8 left-0">
//                 <div className="flex flex-col items-center justify-center mt-20">
//                   <img src={CheckBoxBlue} alt="Checkbox" className="mb-8" />
//                   <div className="border-l-4 border-[#E8E8E8]/50 h-[43vh] sm:h-64 md:h-52 lg:h-80 xl:h-[13rem]"></div>
//                 </div>
//               </div>
//               <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
//                 <div className="w-full md:w-[42%] flex flex-col">
//                   <div className="text-2xl md:text-4xl font-bold">
//                     <img src={Fade1} className="w-20" alt="Invest in a Model Portfolio" />
//                     Invest in a Model Portfolio
//                   </div>
//                   <div className="text-base md:text-xl mt-4">
//                     Choose and invest in a model portfolio that suits your strategy.
//                   </div>
//                 </div>
//                 <div className="w-full md:w-[250px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-0">
//                   <img src={Model1} alt="Model 1" className="w-[85%] md:w-full ml-11 md:ml-14" width={225} height={225} />
//                 </div>
//               </div>
//             </div>

//             {/* Step 2 */}
//             <div className="md:w-[70rem] mb-20 flex flex-col md:flex-row justify-between">
//               <div className="flex justify-center items-center absolute w-8 left-0">
//                 <div className="flex flex-col items-center justify-center mt-20">
//                   <img src={Checkbox} alt="Checkbox" className="mb-6" />
//                   <div className="border-l-4 mt-4 border-[#E8E8E8]/75 h-[50vh] sm:h-64 md:h-64 lg:h-80 xl:h-[12rem]"></div>
//                 </div>
//               </div>
//               <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
//                 <div className="w-full md:w-[60%] flex flex-col">
//                   <div className="text-2xl md:text-4xl font-bold">
//                     <img src={Fade2} className="w-20" alt="Track Performance" />
//                     Track Performance
//                   </div>
//                   <div className="text-base md:text-xl mt-4">
//                     Monitor the performance of your model portfolio on your dashboard.
//                   </div>
//                 </div>
//                 <div className="w-full md:w-[200px] h-full md:h-[150px] flex items-center justify-center md:justify-end rounded-xl  mt-14 md:mt-14">
//                   <img src={Model2} alt="Model 2" className="w-[80%] md:w-full object-cover ml-11" width={180} height={135} />
//                 </div>
//               </div>
//             </div>

//             {/* Step 3 */}
//             <div className="md:w-[70rem] flex flex-col md:flex-row justify-between">
//               <div className="flex justify-center items-center absolute w-8 left-0">
//                 <div className="flex flex-col items-center justify-center mt-20">
//                   <img src={Checkbox} alt="Checkbox" />
//                 </div>
//               </div>
//               <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
//                 <div className="w-full md:w-[60%] flex flex-col text-black">
//                   <div className="text-2xl md:text-4xl font-bold">
//                     <img src={Fade3} className="w-24" alt="Approve Rebalancing" />
//                     Approve Rebalancing
//                   </div>
//                   <div className="text-base md:text-xl mt-4">
//                     Accept periodic rebalancing requests. If not, a repair request will be sent by your advisor to improve portfolio health.
//                   </div>
//                 </div>
//                 <div className="w-full md:w-[200px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-0">
//                   <img src={Model3} alt="Model 3" className="w-[80%] md:w-full ml-11 md:ml-5" width={200} height={200} />
//                 </div>
//               </div>
//             </div>

//             <div className="flex items-center justify-center">
//             <Link
//               to="subscriptionSection" 
//               smooth={true} 
//               duration={800} 
//               className="inline-block w-full sm:w-auto mt-20 rounded-md bg-[#0D084D] text-white font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
//             >
//               {/* <button className="w-full px-10 py-3 text-base sm:text-sm lg:text-base">
//                 View All Model Portfolios
//               </button> */}
//             </Link>
//           </div>





            
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ProcessSection;




const ProcessSection = () => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabSwitch = (Index) => {
    setActiveTab(Index);
  };

  return (
    <div className="mb-24">
      <div className="font-montserrat text-[28px] md:text-[3rem] lg:text-[3rem] font-bold text-[#1A1A1A] text-center mt-24">
        How to Invest ?
      </div>

      <div className="group relative flex flex-row pt-8 items-center gap-4 justify-center overflow-x-auto overflow-y-hidden border-gray-200 dark:border-gray-700 border-b-[1px] sm:border-b-[2px] sm:border-gray-400 dark:sm:border-gray-200 lg:gap-10 max-w-screen-md mx-auto">
      {/* <div className="absolute inset-0 border-black opacity-0 group-hover:opacity-100 transition-opacity duration-300 pointer-events-none z-20"></div>
      <button
        className={`relative font-poppins text-[15px] lg:text-lg inline-flex items-center pb-2 font-bold h-30 px-4 -mb-px text-center border-b-[3px] sm:text-base ${
          activeTab === 0
            ? "text-black border-black z-10"
            : "text-[#808080] border-transparent hover:text-black hover:border-black z-10"
        }`}
        onClick={() => handleTabSwitch(0)}
      >
        Stock Recommendations Plan
      </button>

      <button
        className={`relative inline-flex text-[15px] lg:text-lg items-center pb-2 font-bold h-30 px-4 -mb-px text-center border-b-[3px] sm:text-base ${
          activeTab === 1
            ? "text-black border-black z-10"
            : "text-[#808080] border-transparent hover:text-black hover:border-black z-10"
        }`}
        onClick={() => handleTabSwitch(1)}
      >
        Model Portfolio Plan
      </button> */}
    </div>
      {activeTab === 0 && (
        <div className="flex flex-col justify-center mx-2 mt-9 md:mt-12">
          <div className="relative mx-auto mt-12 md:mt-12">
            <div className="md:w-[70rem] mb-20 flex flex-col md:flex-row justify-between">
              <div className="flex justify-center items-center absolute w-8 left-0">
                <div className="flex flex-col items-center justify-center mt-20">
                  <img src={Checkbox1} alt="Checkbox" className="mb-5" />
                  <div className="border-l-4 border-[#E8B634] h-[50vh] sm:h-64 md:h-52 lg:h-80 xl:h-[12rem]"></div>
                </div>
              </div>

              <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-[42%] flex flex-col">
                  <div className="text-2xl md:text-4xl font-bold">
                    <img src={Fade1} className="w-20" alt="Choose Your Plan" />
                    Choose Your Plan
                  </div>
                  <div className="text-base md:text-xl mt-4">
                    Choose the stock tips plan that fits your investment goals.
                  </div>
                </div>

                <div className="w-full md:w-[280px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-0 pr-4 md:pr-0">
                  <img
                    src={Step1}
                    alt="Step 1"
                    className="w-full max-w-[250px] md:max-w-full ml-14"
                  />
                </div>
              </div>
            </div>

            {/* Step 2 */}
            <div className="md:w-[70rem] mb-20 flex flex-col md:flex-row justify-between">
              {/* Timeline Icon */}
              <div className="flex justify-center items-center absolute w-8 left-0">
                <div className="flex flex-col items-center justify-center mt-24">
                  <img src={Checkbox} alt="Checkbox" className="mb-2" />
                  <div className="border-l-4 mt-4 border-[#E8E8E8]/75 h-[60vh] sm:h-64 md:h-64 lg:h-80 xl:h-[12rem]"></div>
                </div>
              </div>

              {/* Content */}
              <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-[60%] flex flex-col">
                  <div className="text-2xl md:text-4xl font-bold">
                    <img
                      src={Fade2}
                      className="w-20"
                      alt="Receive Tips & Invest"
                    />
                    Receive Recommendations & Invest
                  </div>
                  <div className="text-base md:text-xl mt-4">
                    Get stock recommendations on your dashboard, connect your
                    broker, and place orders with a few clicks.
                  </div>
                </div>

                {/* Image */}
                <div className="w-full md:w-[250px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-0 pr-4 md:pr-0">
                  <img src={Step2} alt="Step 2" className="w-[90%] ml-10" />
                </div>
              </div>
            </div>

            {/* Step 3 */}
            <div className="md:w-[70rem] flex flex-col md:flex-row justify-between">
              {/* Timeline Icon */}
              <div className="flex justify-center items-center absolute w-8 left-0">
                <div className="flex flex-col items-center justify-center mt-20">
                  <img src={Checkbox} alt="Checkbox" />
                </div>
              </div>

              {/* Content */}
              <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-[60%] flex flex-col text-black">
                  <div className="text-2xl md:text-4xl font-bold">
                    <img
                      src={Fade3}
                      className="w-24"
                      alt="Track your Portfolio"
                    />
                    Track Your Portfolio
                  </div>
                  <div className="text-base md:text-xl mt-4">
                    Once you've invested, monitor your portfolio to see how your
                    stocks are performing. Keep an eye on your investments as
                    they grow.
                  </div>
                </div>

                {/* Image */}
                <div className="w-full md:w-[250px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-14">
                  <img src={Step3} alt="Step 3" className="w-[75%] ml-11" />
                </div>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">


          <Link
        to="subscriptionSection" 
        smooth={true} 
        duration={800} 
        className="inline-block w-full sm:w-auto mt-20 rounded-md bg-black text-white font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear"
        >
          <button className="w-full px-6 py-3 text-base sm:text-sm lg:text-base">
            View All Stock Recommendations Plans
          </button>
        </Link>

          </div>
        </div>
      )}
      {activeTab === 1 && (
        <div className="flex flex-col justify-center mx-2 mt-9 md:mt-12">
          <div className="relative mx-auto mt-12 md:mt-12">
            {/* Step 1 */}
            <div className="md:w-[70rem] mb-20 flex flex-col md:flex-row justify-between">
              {/* Timeline Icon */}
              <div className="flex justify-center items-center absolute w-8 left-0">
                <div className="flex flex-col items-center justify-center mt-20">
                  <img src={CheckBoxBlue} alt="Checkbox" className="mb-8" />
                  <div className="border-l-4 border-[#E8E8E8]/50 h-[43vh] sm:h-64 md:h-52 lg:h-80 xl:h-[13rem]"></div>
                </div>
              </div>

              {/* Content */}
              <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-[42%] flex flex-col">
                  <div className="text-2xl md:text-4xl font-bold">
                    <img
                      src={Fade1}
                      className="w-20"
                      alt="Invest in a Model Portfolio"
                    />
                    Invest in a Model Portfolio
                  </div>
                  <div className="text-base md:text-xl mt-4">
                    Choose and invest in a model portfolio that suits your
                    strategy.
                  </div>
                </div>

                {/* Image */}
                <div className="w-full md:w-[250px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-0">
                  <img
                    src={Model1}
                    alt="Model 1"
                    className="w-[85%] md:w-full ml-11 md:ml-14"
                    width={225}
                    height={225}
                  />
                </div>
              </div>
            </div>

            {/* Step 2 */}
            <div className="md:w-[70rem] mb-20 flex flex-col md:flex-row justify-between">
              {/* Timeline Icon */}
              <div className="flex justify-center items-center absolute w-8 left-0">
                <div className="flex flex-col items-center justify-center mt-20">
                  <img src={Checkbox} alt="Checkbox" className="mb-6" />
                  <div className="border-l-4 mt-4 border-[#E8E8E8]/75 h-[50vh] sm:h-64 md:h-64 lg:h-80 xl:h-[12rem]"></div>
                </div>
              </div>

              {/* Content */}
              <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-[60%] flex flex-col">
                  <div className="text-2xl md:text-4xl font-bold">
                    <img src={Fade2} className="w-20" alt="Track Performance" />
                    Track Performance
                  </div>
                  <div className="text-base md:text-xl mt-4">
                    Monitor the performance of your model portfolio on your
                    dashboard.
                  </div>
                </div>

                {/* Image */}
                <div className="w-full md:w-[200px] h-full md:h-[150px] flex items-center justify-center md:justify-end rounded-xl  mt-14 md:mt-14">
                  <img
                    src={Model2}
                    alt="Model 2"
                    className="w-[80%] md:w-full object-cover ml-11"
                    width={180}
                    height={135}
                  />{" "}
                </div>
              </div>
            </div>

            {/* Step 3 */}
            <div className="md:w-[70rem] flex flex-col md:flex-row justify-between">
              {/* Timeline Icon */}
              <div className="flex justify-center items-center absolute w-8 left-0">
                <div className="flex flex-col items-center justify-center mt-20">
                  <img src={Checkbox} alt="Checkbox" />
                </div>
              </div>

              {/* Content */}
              <div className="ml-16 md:ml-20 flex-grow flex flex-col md:flex-row justify-between">
                <div className="w-full md:w-[60%] flex flex-col text-black">
                  <div className="text-2xl md:text-4xl font-bold">
                    <img
                      src={Fade3}
                      className="w-24"
                      alt="Approve Rebalancing"
                    />
                    Approve Rebalancing
                  </div>
                  <div className="text-base md:text-xl mt-4">
                    Accept periodic rebalancing requests. If not, a repair
                    request will be sent by your advisor to improve portfolio
                    health.
                  </div>
                </div>

                {/* Image */}
                <div className="w-full md:w-[200px] flex items-center justify-center md:justify-end rounded-xl mt-6 md:mt-0">
                  <img
                    src={Model3}
                    alt="Model 3"
                    className="w-[80%] md:w-full ml-11 md:ml-5"
                    width={200}
                    height={200}
                  />{" "}
                </div>
              </div>
            </div>
          </div>

          {/* <div className="flex items-center justify-center">
            <button className="w-72 h-11 md:w-[15%] mt-20 py-2 px-1 rounded-md bg-black text-white text-[20px] sm:text-[15px] lg:text-base xxl:text-[15px] font-poppins font-medium hover:scale-[1.03] transition-all duration-150 ease-linear">
      Coming Soon            </button>
          </div> */}
 {/* <div className="flex justify-center items-center ">
      <h1 
        className="text-4xl md:text-6xl font-bold text-black tracking-wide"
        style={{ fontFamily: "'Poppins', sans-serif" }}
      >
        Coming Soon
      </h1>
    </div> */}



        </div>
      )}

      <div></div>
    </div>
  );
};

export default ProcessSection;

