"use client";

import React, { useEffect, useState, useRef } from "react";
import { CircleCheck } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Bg from "./assests/Subscritionsection/bg.png"
import Check from "./assests/Subscritionsection/check.png"
import Tag from "./assests/Subscritionsection/tag.png"

const SubscriptionSection = ({
  allPricingPlan = [],
  handlePricingCardClick,
}) => {
  const [pricingPlans, setPricingPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showSkeleton, setShowSkeleton] = useState(true);
  const rowRefs = useRef([]);
  const headerRefs = useRef([]);

  useEffect(() => {
    if (allPricingPlan.length > 0) {
      setPricingPlans(allPricingPlan);
      setIsLoading(false);
      setShowSkeleton(false); // Stop showing the skeleton when data is available
    } else {
      const timer = setTimeout(() => {
        setShowSkeleton(false); // Hide skeleton after 3 seconds
      }, 3000);

      return () => clearTimeout(timer); // Cleanup timer on component unmount
    }
  }, [allPricingPlan]);

  useEffect(() => {
    if (!isLoading) {
      adjustCardHeights();
      adjustHeaderHeights();
    }
  }, [isLoading, pricingPlans]);

  const adjustCardHeights = () => {
    const rows = [];
    let currentRow = [];
    rowRefs.current.forEach((card, index) => {
      if (card) {
        currentRow.push(card);
        if ((index + 1) % 3 === 0 || index === rowRefs.current.length - 1) {
          rows.push(currentRow);
          currentRow = [];
        }
      }
    });

    rows.forEach((row) => {
      const descriptionContainers = row.map((card) =>
        card.querySelector(".description-container")
      );
      const hasDescription = descriptionContainers.some(
        (container) => container?.textContent?.trim().length > 0
      );

      if (hasDescription) {
        const maxHeight = Math.max(
          ...descriptionContainers.map(
            (container) => container?.scrollHeight || 0
          )
        );
        descriptionContainers.forEach((container) => {
          if (container) {
            container.style.height = `${maxHeight}px`;
          }
        });
      } else {
        descriptionContainers.forEach((container) => {
          if (container) {
            container.style.height = "auto";
          }
        });
      }
    });
  };

  const adjustHeaderHeights = () => {
    const maxHeight = Math.max(
      ...headerRefs.current.map((header) => header?.scrollHeight || 0)
    );
    headerRefs.current.forEach((header) => {
      if (header) {
        header.style.height = `${maxHeight}px`;
      }
    });
  };
  // Function to format price with comma for 5-digit numbers
const formatPrice = (price) => {
  if (typeof price === 'number' && price >= 10000 && price < 100000) {
    return price.toString().replace(/^(\d{2})(\d+)$/, '$1,$2');
  }
  return price;
};

const calculateDiscount = (price) => {
  if (price === 10000) return { amount: 2000, percentage: 17 };
  if (price === 30000) return { amount: 18000, percentage: 38 };
  return null;
};


  const SkeletonCard = () => (
    <div className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-[350px] relative font-poppins min-h-[200px] rounded-xl bg-white border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4">
      <div className="flex items-start pl-7 pt-2 pb-7 md:items-center">
        <div className="overflow-hidden rounded-lg">
          <Skeleton width={128} height={25} />
          <Skeleton width={100} height={30} />
        </div>
      </div>
      <div className="flex justify-center items-center">
        <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
      </div>
      <div className="flex flex-col items-center pt-7 p-4 space-y-4 w-90">
        <div className="font-poppins text-[12.5px] min-h-[220px] flex flex-col items-left justify-start space-y-2 text-[#666666]">
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
          <Skeleton width={200} height={5} />
        </div>
      </div>
      <div className="p-4">
        <div className="flex justify-center align">
          <Skeleton width={280} height={44} />
        </div>
      </div>
    </div>
  );

  if (!showSkeleton && allPricingPlan.length === 0) {
    return null; // Return null after 3 seconds if no data
  }
  return (
    <div className="pt-20 pb-32" id="subscriptionSection">
      <h1 className="text-[28px] sm:text-3xl md:text-4xl font-bold text-black text-center mt-4 sm:mt-6 md:mt-8 px-4 leading-tight">
      Stock Tips for Equity Momentum Plans
            </h1>

      <div className="container mx-auto px-4 sm:px-20 pt-10 md:pt-14 lg:pt-14">
        <div className="flex flex-wrap justify-center gap-8">
          {isLoading
            ? Array(3)
                .fill(0)
                .map((_, i) => <SkeletonCard key={i} />)
            : pricingPlans.map((ele, i) => (
                <div
                  key={i}
                  ref={(el) => (rowRefs.current[i] = el)}
                  className="w-full sm:w-[calc(50%-12px)] lg:w-[calc(33.333%-16px)] max-w-[350px] relative font-poppins rounded-xl bg-[#FFFFFF] border-[1px] border-[#000000]/10 shadow-[0px_4px_4px_0px_rgba(0,0,0,0.06)] py-4 cursor-pointer"
                  onClick={
                    ele?.subscription
                      ? undefined
                      : () => handlePricingCardClick(ele)
                  }
                >
                  <div 
                  
                  ref={(el) => (headerRefs.current[i] = el)}
                  className="flex items-start pl-7 pt-2 pb-7 md:items-center">
                    <div className="overflow-hidden rounded-lg">
                    <div className="flex flex-row items-center justify-between">
                        <h2 className="font-poppins text-[22px] font-bold leading-[35px] text-left text-[#333333]">
                          {ele?.name}
                        </h2>
                   
                      </div>
                      <div className="flex items-baseline">
                        
                        <h2 className="font-poppins text-[40px] font-bold text-[#333333] leading-none">
                          ₹
                          {formatPrice(ele?.amount ||
                            (ele?.pricing &&
                              (ele.pricing.monthly ||
                                ele.pricing.quarterly ||
                                ele.pricing.halfYearly ||
                                ele.pricing.yearly)) ||
                            "N/A")}
                        </h2>
                        {ele?.frequency?.length !== 0 && (
                          <p className="text-[16px] text-[#95989C] font-normal ml-1">
                            /{ele?.frequency}
                          </p>
                          
                        )}
                           
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-start items-start">
                    {" "}
                    {/* Update 1 */}
                    <div className="w-[80%] border-t-[1px] border-b-[1px] border-[#000000]/10"></div>
                  </div>
                  <div className="flex flex-col items-start pt-7 p-4 space-y-4 w-90">
                    {" "}
                    {/* Update 2 */}
                    <div className="font-poppins text-[12.5px] flex flex-row items-start space-x-2 text-[#666666] description-container">
                      <CircleCheck
                        size={20}
                        className="text-white fill-[#16826C] shrink-0"
                      />
                      <span className="text-sm font-poppins text-left text-gray-600">
                        {ele?.description || "No description available"}
                      </span>
                    </div>
                  </div>
                  <div className="p-4">
                    <div className="flex justify-center">
                      <button className="w-[19rem] h-11 py-2 px-1 bg-white rounded-md border-black border-[2px] text-black text-base font-semibold hover:bg-[#0B2465] hover:text-white hover:border-black transition-all duration-150 ease-linear">
                        {ele?.subscription ? "Subscribed" : "Subscribe Now"}
                      </button>




                    </div>
                    {calculateDiscount(ele?.amount) && (
  <div className="absolute top-0 right-0 w-96 h-32 overflow-hidden">
  <div className="absolute top-[40px] right-[-50px] rotate-45 bg-red-600 text-white text-[9px] font-semibold py-1 w-[200px] text-center shadow-md">
    Save ₹{formatPrice(calculateDiscount(ele?.amount).amount)}{' '}
    (<span className="font-normal">approx.</span> {calculateDiscount(ele?.amount).percentage}%)
  </div>
</div>
)}

                  </div>
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default SubscriptionSection;

// const DiscountButton = ({ months, amount }) => (
//   <div className="bg-[#0B2465] text-white text-xs font-bold py-1 px-2 rounded-full ml-2 ">
//    Save ₹{amount}
//   </div>
// )

// const SubscriptionSection = () => {
//   return (
//     <div className="font-poppins mb-20" id="subscriptionSection">
//       <div className="px-4 pt-5 sm:pt-20">
//         <h1 className="text-4xl font-bold text-black text-center mb-8">
//           Stock Tips for Equity Momentum Plans
//         </h1>

//         <div className="flex flex-col sm:flex-row justify-center items-center sm:items-stretch gap-8">
//           {/* STARTER Card */}
//           <div className="relative border border-gray-200 bg-white rounded-2xl p-6 shadow-lg w-full sm:w-[328.64px] h-[380px] flex flex-col justify-between mb-8 sm:mb-0">
//             <div>
//               <h3 className="text-lg font-bold text-gray-900 mb-2">1 Month</h3>
//               <div className="flex items-baseline">
//                 <span className="text-2xl font-bold">₹4,000</span>
//                 {/* <span className="text-gray-500 ml-2 text-sm">/month</span> */}
//               </div>
//               <div className="w-full h-px bg-gray-200 my-6"></div>
//             </div>

//             <div className="space-y-3 flex-grow">
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//             </div>

//             <button className="w-full  py-3 rounded-md text-xs font-medium transition-all border border-black text-black hover:bg-[#0B2465] hover:text-white">
//               Get Started
//             </button>
//           </div>

//           {/* PRO Card */}
//           <div className="relative border border-gray-200 bg-white rounded-2xl p-6 shadow-lg w-full sm:w-[328.64px] h-[380px] flex flex-col justify-between mb-8 sm:mb-0">

//             <img 
//               src={Tag}
//               alt="Most Popular" 
//               className="absolute -top-5 sm:-top-7 -right-5 sm:-right-7 w-32 h-30 sm:w-44 sm:h-auto transform rotate-20"
//             />
//             <div>
//             <div className="flex items-center space-x-2 mb-2">
//             <h3 className="text-lg font-bold text-gray-900 ">3 Month 
//               </h3>
//               <DiscountButton months={3} amount={2000}  />
//               </div>
//               <div className="flex items-baseline">
//                 <span className="text-2xl font-bold">₹10,000</span>
//                 {/* <span className="text-gray-500 ml-2 text-sm">/month</span> */}
//               </div>
//               <div className="w-full h-px bg-gray-200 my-6"></div>
//             </div>

//             <div className="space-y-3 flex-grow">
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//             </div>

//             <button className="w-full py-3 rounded-md text-xs font-medium transition-all bg-[#0B2465] text-white hover:bg-[#0B2465]">
//               Get Started
//             </button>
//           </div>

//           {/* PLATINUM Card */}
//           <div className="relative border border-gray-200 bg-white rounded-2xl p-6 shadow-lg w-full sm:w-[328.64px] h-[380px] flex flex-col justify-between mb-8 sm:mb-0 card-with-top-shadow">
//             <img 
//               src={Tag}
//               alt="Most Popular" 
//               className="absolute -top-5 sm:-top-7 -right-5 sm:-right-7 w-32 h-30 sm:w-44 sm:h-auto transform rotate-20"
//             />
//             <div>
//             <div className="flex items-center space-x-2 mb-2">
//             <h3 className="text-lg font-bold text-gray-900 ">12 Month 
//               </h3>
//               <DiscountButton months={3} amount={'18,000'}  />
//               </div>              <div className="flex items-baseline">
//                 <span className="text-2xl font-bold">₹30,000</span>
//                 {/* <span className="text-gray-500 ml-2 text-sm">/month</span> */}
//               </div>
//               <div className="w-full h-px bg-gray-200 my-6"></div>
//             </div>

//             <div className="space-y-3 flex-grow">
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//               <Feature text="Lorem ipsum dolor sit amet consectetur" />
//             </div>

//             <button className="w-full  py-3 rounded-md text-xs font-medium transition-all border border-black text-black hover:bg-[#0B2465] hover:text-white">
//               Get Started
//             </button>
//           </div>
//         </div>
//       </div>
//     </div>
//   )
// }

// const Feature = ({ text }) => (
//   <div className="flex items-start gap-2">
//     <img src={Check} alt="Check" className="h-4 w-4 shrink-0" />
//     <span className="text-xs text-gray-600">{text}</span>
//   </div>
// )

// export default SubscriptionSection;
