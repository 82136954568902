import React from "react";
import Sbi from "./assests/sbi.png";
import Exp from "./assests/Experience.png";
import Advisor from "./assests/About/advisor.webp";
const AboutSection = () => {
  return (
    <div
      className="flex flex-col  lg:flex-row bg-stone-100"
      section
      id="aboutSection"
    >
      <div className="lg:w-1/3 sm:w-[50%] lg:h-auto">
        <div className="md:w-[440px] h-[570px] sm:w-full sm:h-full bg-cover">
          <img
            src={Advisor}
            alt="About"
            className="object-cover w-full h-full p-4 sm:p-0"
          />
        </div>
      </div>

      <div className="flex flex-col justify-center items-center w-full lg:w-2/3 lg:h-[36rem] px-4 lg:px-0 mx-auto mt-0 lg:mt-0">
        <div className="w-[90%] pb-10 md:pb-0 lg:pb-0 lg:w-full">
          <div className="font-Montserrat mt-20 text-[16px] text-neutral-500 font-semibold text-center lg:text-left">
            ABOUT ADVISOR
          </div>
          <div className="font-Montserrat text-[#171717] mt-4 font-bold text-2xl sm:text-3xl md:text-[50px] lg:text-4xl text-center lg:text-left">
            Aditya Arora
          </div>

          <p className="font-poppins font-normal mt-10 text-[17px] text-[#7B7B7B] text-left  w-full lg:w-[80%] mx-auto lg:mx-0 lg:text-left">
          Our aim is to provide investors with the best market outlook and guidance, enabling them to 
optimize their capital and seize market opportunities. We specialize in stock option 
derivatives, cash market, and commodities. Adlytick delivers timely and high-quality 
research to empower clients in making well-timed decisions and capturing golden 
opportunities while safeguarding their interests. Our strategies encompass both bullish and 
bearish trends, leveraging macroeconomics, technical analysis, and derivative data. What 
sets us apart is our independence from herd mentality. Our predictions are grounded in the 
founder’s extensive market skills and knowledge, acquired over a decade of experiencing the 
highs and lows of the stock market. Trust that your money is secure with us at Adlytick.          </p>



<div className="flex flex-nowrap overflow-x-auto justify-start lg:flex-wrap lg:justify-start gap-4 lg:gap-8 mt-8 lg:mt-12 mb-12 px-1">
  <div className="flex items-center gap-0 lg:gap-3 max-w-full">
              <div className="p-1 lg:p-2">
                <img src={Exp} alt="Experience Logo" className="w-8 h-8 lg:w-10 lg:h-12" />
              </div>
              <div>
                <div className="font-bold text-base lg:text-xl">10+ years</div>
                <div className="text-gray-500 text-xs lg:text-base">Years of Experience</div>
              </div>
            </div>

            <div className="flex items-center gap-0 lg:gap-3 max-w-full">
            <div className="p-1 lg:p-2">
                <img src={Sbi} alt="SEBI Logo" className="w-16 h-8 lg:w-12 lg:h-12" />
              </div>
              <div>
                <div className="font-bold text-base lg:text-xl">INH000011440</div>
                <div className="text-gray-500 text-xs lg:text-base">SEBI Registered</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSection;